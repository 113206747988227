<template>
  <div class="top-lang-selector" :class="{ white }">
    <md-icon class="lang-icon" @click.native="$refs.option.click()"
      >language</md-icon
    >
    <select v-model="selected" v-if="showSelector" ref="option">
      <option
        :value="option.value"
        :key="option.text"
        v-for="option in options"
      >
        {{ option.text }}
      </option>
    </select>
    <md-icon class="arrow-icon" @click.native="$refs.option.click()"
      >expand_more</md-icon
    >
  </div>
</template>

<script>
export default {
  props: ['white'],
  data() {
    return {
      selected: window.userLocale,
      options: [],
      showSelector: true,
    }
  },
  created() {
    window.allLocales.forEach(locale => {
      this.options.push({
        value: locale,
        text: this.$t('locales.' + locale),
      })
    })
  },
  methods: {
    apply() {
      localStorage.lang = this.selected

      if (this.$store.isAuthed)
        this.$api.patch(`user`, {
          prefferedLanguageCode: this.selected,
        })
      window.userLocale = this.selected
      this.$i18n.locale = this.selected
      this.$api.defaults.headers.common.Locale = window.userLocale
    },
  },
  watch: {
    selected() {
      this.apply()
    },
    white() {
      this.showSelector = false
      this.$nextTick(() => {
        this.showSelector = true
      })
    },
  },
}
</script>

<style lang="scss">
.top-lang-selector {
  display: flex;
  align-items: center;
  justify-content: center;

  &.white {
    select {
      color: white;
    }
    .md-icon {
      color: white;
    }
  }

  select {
    appearance: none;
    background-color: transparent;
    outline: none;
    border: 0;
    width: fit-content;
    max-width: 90px;
    text-align: center;
    cursor: pointer;

    option {
      color: black;
    }
  }

  .lang-icon {
    margin-right: 5px;
    font-size: 21px !important;
  }
  .arrow-icon {
    font-size: 18px !important;
    margin-left: -3px;
  }
}
</style>
