var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"top-menu",style:({ paddingBottom: _vm.avatar !== undefined ? '0' : '50px' }),attrs:{"id":"top-menu"}},[_c('div',{ref:"fixed",staticClass:"fixed",style:({
      width: _vm.fixedWidth + 'px',
      backgroundColor: _vm.finalBgColor,
      boxShadow:
        _vm.scrollY > 120 && !_vm.noDim ? '0 1px 11px 1px rgba(0,0,0,.15)' : '',
    })},[_c('div',{ref:"fixedLeft",staticClass:"left"},[(
          !_vm.hideBack &&
            !_vm.queryHideBackBtn &&
            (_vm.customBack || _vm.$store.routerHistory.length > 0)
        )?_c('div',{staticClass:"back",on:{"click":_vm.back}},[_c('md-icon',[_vm._v("arrow_back")])],1):_vm._e(),(_vm.showLangSelector)?_c('TopLangSelector'):_vm._e()],1),_c('div',{staticClass:"info"},[(_vm.title)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.description)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription),expression:"showDescription"}],ref:"description",staticClass:"description",style:({ fontSize: _vm.descriptionFontSize + 'px' })},[_vm._v(" "+_vm._s(_vm.$stripHtml(_vm.description))+" ")]):_vm._e(),(_vm.description)?_c('div',{ref:"ghostDescription",staticClass:"description ghost-description",style:({ fontSize: _vm.descriptionFontSize + 'px' })},[_vm._v(" "+_vm._s(_vm.$stripHtml(_vm.description))+" ")]):_vm._e()])]),(_vm.avatar != undefined)?_c('div',{staticClass:"avatar",style:({ paddingTop: _vm.invertGradient ? '0' : '50px' })},[_c('div',{directives:[{name:"rellax",rawName:"v-rellax"}],staticClass:"parallax"},[_c('div',{staticClass:"image",style:({ backgroundImage: ("url(" + _vm.avatar + ")") })}),_c('div',{staticClass:"gradient",class:{ invert: _vm.invertGradient }}),_c('div',{staticClass:"whiteout",style:({ opacity: _vm.scrollY > 120 ? '1' : '0' })})])]):_vm._e(),_c('div',{staticClass:"content"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }