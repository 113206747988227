<template>
  <div
    id="top-menu"
    ref="top-menu"
    :style="{ paddingBottom: avatar !== undefined ? '0' : '50px' }"
  >
    <div
      class="fixed"
      ref="fixed"
      :style="{
        width: fixedWidth + 'px',
        backgroundColor: finalBgColor,
        boxShadow:
          scrollY > 120 && !noDim ? '0 1px 11px 1px rgba(0,0,0,.15)' : '',
      }"
    >
      <div class="left" ref="fixedLeft">
        <div
          class="back"
          v-if="
            !hideBack &&
              !queryHideBackBtn &&
              (customBack || $store.routerHistory.length > 0)
          "
          @click="back"
        >
          <md-icon>arrow_back</md-icon>
        </div>

        <TopLangSelector v-if="showLangSelector" />
      </div>

      <div class="info">
        <div class="title" v-if="title">{{ title }}</div>
        <div
          class="description"
          v-if="description"
          ref="description"
          v-show="showDescription"
          :style="{ fontSize: descriptionFontSize + 'px' }"
        >
          {{ $stripHtml(description) }}
        </div>
        <div
          class="description ghost-description"
          ref="ghostDescription"
          v-if="description"
          :style="{ fontSize: descriptionFontSize + 'px' }"
        >
          {{ $stripHtml(description) }}
        </div>
      </div>
    </div>
    <div
      class="avatar"
      v-if="avatar != undefined"
      :style="{ paddingTop: invertGradient ? '0' : '50px' }"
    >
      <div class="parallax" v-rellax>
        <div class="image" :style="{ backgroundImage: `url(${avatar})` }"></div>
        <div class="gradient" :class="{ invert: invertGradient }"></div>
        <div
          class="whiteout"
          :style="{ opacity: scrollY > 120 ? '1' : '0' }"
        ></div>
      </div>
    </div>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from 'throttle-debounce'
import interpolate from 'color-interpolate'
import TopLangSelector from '@/components/Hotel/TopLangSelector'
export default {
  props: [
    'title',
    'description',
    'avatar',
    'customBack',
    'invertGradient',
    'bgColor',
    'noDim',
    'showLangSelector',
    'hideBack',
  ],
  components: { TopLangSelector },
  data() {
    return {
      scrollY: window.scrollY,
      fixedWidth: this.$full ? this.routerWidth : window.innerWidth,
      descriptionFontSize: 16,
      showDescription: false,
      debounceUpdateDescriptionFontSize: debounce(
        100,
        this.updateDescriptionFontSize,
      ),
    }
  },
  mounted() {
    this.debounceUpdateDescriptionFontSize()
    const routerInterval = setInterval(() => {
      if (this.routerWidth != 0) {
        this.updateFixedWidth()
        clearInterval(routerInterval)
      }
    }, 1)
  },
  methods: {
    back() {
      //go to latest route if avaialble
      if (this.$store.routerHistory.length > 0) {
        this.$router.push(this.$store.routerHistory.pop().fullPath).then(() => {
          this.$store.routerHistory.pop()
        })
        return
      }
      if (this.customBack) this.customBack()
    },
    updateFixedWidth() {
      this.fixedWidth = this.$full ? this.routerWidth : window.innerWidth
    },
    async updateDescriptionFontSize(previousResult = 0, callStackSize = 0) {
      if (callStackSize >= 2) {
        this.showDescription = true
        return
      }
      const ghostDescriptionEl = await this.$waitFor(
        () => this.$refs.ghostDescription,
      )
      const textWidth = ghostDescriptionEl.offsetWidth
      const parentWidth =
        ghostDescriptionEl.parentNode.clientWidth -
        this.$refs.fixedLeft.clientWidth
      const maxFontSize = 16
      const originalFontSize = parseInt(
        window.getComputedStyle(ghostDescriptionEl).fontSize,
      )
      const rate = (maxFontSize - originalFontSize) / textWidth || 1
      this.descriptionFontSize = Math.max(
        10,
        Math.min(
          maxFontSize,
          originalFontSize + rate * (parentWidth - textWidth),
        ),
      ).toFixed(2)
      if (previousResult != this.descriptionFontSize)
        window.requestAnimationFrame(() =>
          this.updateDescriptionFontSize(
            this.descriptionFontSize,
            ++callStackSize,
          ),
        )
      else this.showDescription = true
    },
    _scroll() {
      this.scrollY = window.scrollY
    },
    _resize() {
      this.updateFixedWidth()
      this.$nextTick(this.debounceUpdateDescriptionFontSize)
    },
  },
  computed: {
    queryHideBackBtn() {
      return Boolean(this.$route.query.hideBackBtn)
    },
    routerWidth() {
      return this.$store.routerWidth
    },
    finalBgColor() {
      if (this.bgColor) return this.bgColor

      const scrollState = (this.scrollY * 0.01) / 2

      const startColor = this.invertGradient
        ? 'rgba(255, 255, 255, 0)'
        : 'rgba(255, 255, 255, 1)'
      const endColor = this.noDim
        ? 'rgba(255, 255, 255, 1)'
        : 'rgba(240, 240, 240, 1)'
      const colormap = interpolate([startColor, endColor])
      return colormap(scrollState)
    },
  },
  watch: {
    routerWidth() {
      this.updateFixedWidth()
    },
    description() {
      const int = setInterval(() => {
        if (!this.$refs.ghostDescription) return
        clearInterval(int)
        this.updateDescriptionFontSize()
      }, 250)
    },
  },
}
</script>

<style lang="scss">
#top-menu {
  width: 100%;

  > .fixed {
    z-index: 16;
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    max-height: 60px;

    .left {
      display: flex;
      .back {
        margin-right: 15px;
        width: 28px;
        display: flex;
        align-items: center;
        transition: all 0.25s;
        cursor: pointer;
        z-index: 10;

        .md-icon {
          font-size: 28px !important;
          font-weight: bold;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
    .info {
      flex: 1;
      text-align: right;
      position: relative;

      .title {
        font-family: Oswald;
        font-weight: 200;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 5px;
      }
      .description {
        font-family: Roboto;
        color: rgb(126, 126, 126);
        text-transform: uppercase;
        transition: 0.25s font-size;

        &.ghost-description {
          white-space: nowrap;
          position: absolute;
          visibility: hidden;
          pointer-events: none;
          transition: none;
        }
      }
    }
  }
  > .avatar {
    $height: 200px;
    padding-top: 50px;
    padding-bottom: $height;
    overflow: hidden;

    .parallax {
      .image {
        position: absolute;
        width: 100%;
        height: $height;
        background-position: center;
        background-size: cover;
      }
      .gradient {
        position: absolute;
        width: 100%;
        height: $height;
        background: linear-gradient(white, rgba(255, 255, 255, 0));

        &.invert {
          background: linear-gradient(rgba(255, 255, 255, 0.4), white);
        }
      }
      .whiteout {
        position: absolute;
        width: 100%;
        height: $height;
        background-color: white;
        transition: 0.5s all;
      }
    }
  }
  > .content {
    position: absolute;
    background-color: white;
    width: 100%;
  }
}
</style>
